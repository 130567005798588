<template>
  <div class="px-10">
    <v-row>
      <v-col cols="2">
        <div class="text-h4 primary--text mb-5">ตั้งค่า SMS</div>
      </v-col>
      
      <v-col cols="5" class="text-left mt-2">
        <v-btn color="error" small outlined href="https://manual.permtang.com/documentation/setting/6-setup-sms/" target="_blank"
          ><v-icon small right class="mr-2 mt-1">mdi-book-settings</v-icon> 
          ดูการตั้งค่า SMS
           </v-btn>
      </v-col>
    </v-row>
    <div
      dense
      flat
      rounded
      outlined
      style="
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        border-opacity: 0.2;
      "
    >
      <v-row> 
        <v-col cols="12" class="mt-5 ml-4">
          <span>
            เปิดใช้งาน SMS ( ใช้บริการ SMS ของ www.smsmkt.com โดยค่าบริการ SMS
            ท่านสามารถชำระโดยตรงกับ smsmkt.com ได้เลย )
          </span>
        </v-col>
        <v-col cols="2">
          <v-radio-group
            class="mt-2 ml-10"
            row
            v-model="form.sms_setting.sms_service"
          >
            <v-radio name="" label="ปิด" :value="false"></v-radio>
            <v-radio name="" label="เปิด" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="form.sms_setting.sms_service" cols="3">
          <v-text-field
            label="api key"
            outlined
            dense
            v-model="form.sms_setting.api_key"
            :items="form.sms_setting.api_key"
          ></v-text-field>
        </v-col>
        <v-col v-if="form.sms_setting.sms_service" cols="3">
          <v-text-field
            label="secret key"
            outlined
            dense
            v-model="form.sms_setting.secret_key"
            :items="form.sms_setting.secret_key"
          ></v-text-field>
        </v-col>
        <v-col v-if="form.sms_setting.sms_service" cols="2">
          <v-text-field
            label="sender name"
            outlined
            dense
            v-model="form.sms_setting.sender_name"
            :items="form.sms_setting.sender_name"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="mt-2" v-if="form.sms_setting.sms_service">
          <v-btn
            color="primary"
            small
            outlined
            class="mx-4 px-5"
            @click="getEstimate()"
            >เชื่อมต่อ</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="8"></v-col>
        <v-col cols="2" v-if="form.sms_setting.sms_service">
          <span>สถานะ : {{ status }}</span>
        </v-col>
        <v-col cols="2" v-if="form.sms_setting.sms_service">
          <span
            >จำนวน SMS คงเหลือ :
            <span class="red--text">{{ parseInt(estimate) }}</span></span
          >
        </v-col>
      </v-row>
    </div>
    <div
      dense
      flat
      rounded
      outlined
      class="mt-5"
      style="
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        border-opacity: 0.2;
      "
      v-if="form.sms_setting.sms_service && show_detail"
    >
      <v-row>
        <v-col cols="12" class="mt-5 ml-4"> ทดสอบการส่ง SMS</v-col>
        <v-col cols="3" class="ml-10">
          <v-text-field
            label="เบอร์ทดสอบ"
            outlined
            dense
            v-model="test_number"
            :items="test_number"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="mt-2">
          <v-btn color="primary" small class="px-10" @click="testMessage()"
            >ส่ง</v-btn
          >
        </v-col>
        <v-col cols="3" class="ml-10 mt-2">
          <span>การส่ง : {{ test_result }}</span>
        </v-col>
        <v-col cols="3" class="mt-2"> </v-col>
      </v-row>
    </div>

    <div
      flat
      rounded
      outlined
      class="mt-5"
      style="
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        border-opacity: 0.2;
      "
      v-if="form.sms_setting.sms_service && show_detail"
    >
      <v-row class="my-2">
        <v-col cols="12"> <span class="ml-4">ข้อความ</span> </v-col>
        <v-col cols="12">
          <v-row class="ml-10" cols="12">
            <v-radio-group row v-model="form.sms_setting.sms_tax_extension">
              <span class="mr-5"> SMS รับงานต่อภาษี </span>

              <v-radio name="" label="ปิดส่งอัตโนมัติ" :value="false"></v-radio>
              <v-radio
                name=""
                label="เปิด ส่งอัตโนมัติ (จะส่งอัตโนมัติ เมื่องานภาษีในระบบติดตามงานต่อภาษีดำเนินการเสร็จแล้ว)"
                :value="true"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-textarea
            v-if="form.sms_setting.sms_tax_extension"
            class="ml-10 float-xl-left col-12"
            v-model="tax_extension_message"
            label="พิมพ์ข้อความ พร้อมกดเลือกข้อมูลด้านล่างมาใส่ในข้อความ"
            outlined
            dense
        /></v-col>
        <v-col cols="2"></v-col>

        <v-col cols="5" v-if="form.sms_setting.sms_tax_extension">
          <v-textarea
            class="pr-10 float-xl-right col-12"
            v-model="txtExam1"
            label="ตัวอย่าง"
            outlined
            dense
            disabled
          />
          <span
            style="margin-top: -30px"
            class="
              text-overline
              mr-10
              grey--text
              text--darken-3
              mx-2
              float-right
            "
          >
            ตัวอักษร : {{ txtExam1.length }}/70 เท่ากับ 1 SMS, จำนวน SMS :
            {{ sum_Text1 }}
          </span>
        </v-col>
        <v-col
          cols="5"
          style="margin-top: -40px"
          v-if="form.sms_setting.sms_tax_extension"
        >
          <v-btn
            color="primary"
            small
            outlined
            class="ml-10"
            @click="storeName1()"
            >ชื่อร้าน</v-btn
          >
          <v-btn
            color="primary"
            small
            outlined
            class="ml-5"
            @click="carPlate1()"
            >เลขทะเบียน</v-btn
          ></v-col
        >
        <v-col cols="12"> <v-divider></v-divider> </v-col>
        <v-col cols="12">
          <v-row class="ml-10">
            <v-radio-group row v-model="form.sms_setting.sms_renewal_alert">
              <v-col>
                <span> SMS เตือนต่ออายุ </span>
              </v-col>
              <v-radio
                name=""
                label="ปิดส่งอัตโนมัติ"
                :value="false"
              ></v-radio>
              <v-radio
                name=""
                label="เปิด ส่งอัตโนมัติ (จะส่งอัตโนมัติทุกวันตามเวลาที่ตั้งไว้)"
                :value="true"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>

        <v-col cols="2" class="mr-10"></v-col>
        <v-col cols="8" class="ml-10" style="margin-top: -40px">
          <div v-if="form.sms_setting.sms_renewal_alert">
            <v-radio-group
              row
              v-model="form.sms_setting.sms_renewal_alert_month"
            >
              <span class="mr-4"> ส่งล่วงหน้าก่อนหมดอายุ </span>
              <v-radio name="" label="15 วัน" :value="15"></v-radio>
              <v-radio name="" label="1 เดือน" :value="30"></v-radio>
              <v-radio name="" label="2 เดือน" :value="60"></v-radio>
              <v-radio name="" label="3 เดือน" :value="90"></v-radio>
            </v-radio-group>
            <div style="margin-top: -20px" class="ml-3">
              <v-row>
                <span class="mr-4 mt-5"> เวลาที่จะส่ง </span>
                <v-col cols="3">
                  <v-text-field
                    type="time"
                    width="100px"
                    label="HH:SS"
                    outlined
                    dense
                    v-model="form.sms_setting.sms_renewal_alert_time"
                    :items="form.sms_setting.sms_renewal_alert_time"
                  ></v-text-field> </v-col
              ></v-row>
            </div>
          </div>
        </v-col>

        <v-col cols="5">
          <v-textarea
            v-if="form.sms_setting.sms_renewal_alert"
            class="ml-10 float-xl-left col-12"
            v-model="renewal_alert_message"
            label="พิมพ์ข้อความ พร้อมกดเลือกข้อมูลด้านล่างมาใส่ในข้อความ"
            outlined
            dense
        /></v-col>
        <v-col cols="2"></v-col>

        <v-col cols="5" v-if="form.sms_setting.sms_renewal_alert">
          <v-textarea
            class="pr-10 float-xl-right col-12"
            v-model="txtExam2"
            label="ตัวอย่าง"
            outlined
            dense
            disabled
          />
          <span
            style="margin-top: -30px"
            class="
              text-overline
              mr-10
              grey--text
              text--darken-3
              mx-2
              float-right
            "
          >
            ตัวอักษร : {{ txtExam2.length }}/70 เท่ากับ 1 SMS, จำนวน SMS :
            {{ sum_Text2 }}
          </span>
        </v-col>

        <v-col
          cols="5"
          style="margin-top: -40px"
          v-if="form.sms_setting.sms_renewal_alert"
        >
          <v-btn
            color="success"
            small
            outlined
            class="ml-10"
            @click="storeName2()"
            >ชื่อร้าน
          </v-btn>
          <v-btn
            color="success"
            small
            outlined
            class="ml-5"
            @click="carPlate2()"
            >เลขทะเบียน</v-btn
          >

          <v-btn color="success" small outlined class="ml-5" @click="service2()"
            >บริการ</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-col cols="12" class="text-right mt-5"
      ><v-btn color="grey" outlined class="ml-5 px-5" @click="close()"
        >ปิด</v-btn
      >
      <v-btn color="primary" class="ml-5 mr-10 px-5" @click="save()"
        >บันทึกข้อมูล</v-btn
      ></v-col
    >
  </div>
</template>
<script>
export default {
  data: () => ({
    sumText1: 0,
    sumText2: 0,
    status: 'ไม่เชื่อมต่อ',
    show_detail: false,
    branch_id: '',
    estimate: 0,
    test_number: '',
    test_result: '-',
    form: {
      store_name: '',
      sms_setting: {
        sms_service: false,
        api_key: '',
        secret_key: '',
        sender_name: '',
        sms_tax_extension: false,
        tax_extension_message: '',
        sms_renewal_alert: false,
        sms_renewal_alert_month: 30,
        sms_renewal_alert_time: '00:00',
        renewal_alert_message: '',
      },
    },
    tax_extension_message: '',
    renewal_alert_message: '',
    txt1: '',
    txt2: '',
    car_plate: 'กก-1111',
    service: 'ภาษี พรบ. ประกันรถ',
  }),
  mounted() {
    this.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    close() {
      this.$router.push({ name: 'setting-business-branch' });
    },
    getEstimate() {
      fetch('https://portal-otp.smsmkt.com/api/get-estimate', {
        headers: {
          'Content-Type': 'application/json',
          api_key: this.form.sms_setting.api_key,
          secret_key: this.form.sms_setting.secret_key,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.code == '000') {
            this.estimate = data.result.estimated;
            this.show_detail = true;
            this.status = 'เชื่อมต่อ';
          } else {
            this.show_detail = false;
            this.status = 'ไม่เชื่อมต่อ';
            this.estimate = 0;
          }
        });
    },
    testMessage() {
      fetch('https://portal-otp.smsmkt.com/api/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: this.form.sms_setting.api_key,
          secret_key: this.form.sms_setting.secret_key,
        },
        body: JSON.stringify({
          sender: this.form.sms_setting.sender_name,
          phone: this.test_number,
          message: 'ตั้งค่าระบบ SMS สำเร็จ',
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.code == '000') this.test_result = 'สำเร็จ';
          if (data.code == '107') this.test_result = 'เบอร์โทรศัพท์ผิด';
          if (data.code == '300') this.test_result = 'sender_name ผิด';
          if (data.code == '1001' || data.code == '1004')
            this.test_result = 'api_key หรือ secret_key ผิด';
        });
    },
    getData() {
      let body = {
        token: this.$jwt.sign({ branch_id: this.branch_id }, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          this.tax_extension_message =
            this.form.sms_setting.tax_extension_message;
          this.renewal_alert_message =
            this.form.sms_setting.renewal_alert_message;
          console.log(this.form);
          this.getEstimate();
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    async save() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.form = { branch_id: this.branch_id, ...this.form };
            console.log(this.form);
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/branch/edit_branch`, body)
              .then((res) => {
                this.getEstimate();
                this.$alertSuccess({ title: res.status });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('save()');
          }
          this.loading = false;
        }
      );
    },
    storeName1() {
      this.tax_extension_message = this.tax_extension_message + ' [storeName] ';
    },
    carPlate1() {
      this.tax_extension_message = this.tax_extension_message + ' [carPlate] ';
    },
    storeName2() {
      this.renewal_alert_message = this.renewal_alert_message + ' [storeName] ';
    },
    carPlate2() {
      this.renewal_alert_message = this.renewal_alert_message + ' [carPlate] ';
    },
    service2() {
      this.renewal_alert_message = this.renewal_alert_message + ' [service] ';
    },

    changeTaxExtensionMessageSetter() {
      this.txtExam1 = this.form.sms_setting.tax_extension_message;
    },
    changeRenewalAlertMessageSetter() {
      this.txtExam2 = this.form.sms_setting.renewal_alert_message;
    },
    sendSMS() {},
  },
  watch: {
    tax_extension_message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.form.sms_setting.tax_extension_message =
          this.tax_extension_message;
        this.changeTaxExtensionMessageSetter();
      }
    },
    renewal_alert_message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.form.sms_setting.renewal_alert_message =
          this.renewal_alert_message;
        this.changeRenewalAlertMessageSetter();
      }
    },
  },

  computed: {
    sum_Text1: function () {
      this.sumText1 = 0;
      if (this.txtExam1.length <= 70) {
        this.sumText1 = 1;
      } else if (this.txtExam1.length <= 134) {
        this.sumText1 = 2;
      } else if (this.txtExam1.length <= 201) {
        this.sumText1 = 3;
      } else if (this.txtExam1.length <= 268) {
        this.sumText1 = 4;
      } else if (this.txtExam1.length <= 335) {
        this.sumText1 = 5;
      } else if (this.txtExam1.length <= 402) {
        this.sumText1 = 6;
      } else if (this.txtExam1.length <= 469) {
        this.sumText1 = 7;
      } else if (this.txtExam1.length <= 536) {
        this.sumText1 = 8;
      } else if (this.txtExam1.length <= 603) {
        this.sumText1 = 9;
      } else if (this.txtExam1.length <= 670) {
        this.sumText1 = 10;
      }
      return this.sumText1;
    },
    sum_Text2: function () {
      this.sumText2 = 0;
      if (this.txtExam2.length <= 70) {
        this.sumText2 = 1;
      } else if (this.txtExam2.length <= 134) {
        this.sumText2 = 2;
      } else if (this.txtExam2.length <= 201) {
        this.sumText2 = 3;
      } else if (this.txtExam2.length <= 268) {
        this.sumText2 = 4;
      } else if (this.txtExam2.length <= 335) {
        this.sumText2 = 5;
      } else if (this.txtExam2.length <= 402) {
        this.sumText2 = 6;
      } else if (this.txtExam2.length <= 469) {
        this.sumText2 = 7;
      } else if (this.txtExam2.length <= 536) {
        this.sumText2 = 8;
      } else if (this.txtExam2.length <= 603) {
        this.sumText2 = 9;
      } else if (this.txtExam2.length <= 670) {
        this.sumText2 = 10;
      }
      return this.sumText2;
    },
    txtExam1: {
      get: function () {
        return this.txt1;
      },
      set: function (newValue) {
        this.txt1 = newValue
          .replace('[storeName]', this.form.store_name)
          .replace('[carPlate]', this.car_plate);
      },
    },
    txtExam2: {
      get: function () {
        return this.txt2;
      },
      set: function (newValue) {
        this.txt2 = newValue
          .replace('[storeName]', this.form.store_name)
          .replace('[carPlate]', this.car_plate)
          .replace('[service]', this.service);
      },
    },
  },
};
</script>
